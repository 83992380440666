import React, { useState, useContext, Fragment, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Outlet } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Chip from "@mui/material/Chip";
import Switch from "@mui/material/Switch";
import MobileUnitWindowRight from "./MobileUnitWindowRight";
import { Box } from "@mui/system";
import { MobileUnitContext } from "./context/MobileUnitContext";
const MobileUnitMainBlock = () => {
  const label = { inputProps: { "aria-label": "Color switch demo" } };
  const {
    muDatas,
    setMUDatas,
    addMobileUnit,
    setAddMobileUnit,
    viewMobileUnit,
    setViewMobileUnit, mappedMobileUnitData ,setMappedMobileUnitData,setViewReadOnly
  } = useContext(MobileUnitContext);
  console.log(muDatas, "muDatas");

  // const { data, ...rest } = muDatas;
  // console.log(data, "rest of data");

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "contact_number", headerName: "Number", flex: 1 },
    { field: "emirate_name", headerName: "Location", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.status === 1 && (
              <Chip label="Availbale" color="success" />
            )}
            {params.row.status === 0 && (
              <Chip label="Off Day" color="primary" />
            )}
            {params.row.status === 2 && (
              <Chip label="Engaged" color="warning" />
            )}
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {/* <Button
              // onClick={(e) => onButtonClick(e, params.row)}
              variant="contained"
            >
              Delete
            </Button> */}
            <Switch {...label} defaultChecked color="secondary" />
          </>
        );
      },
    },
  ];
  // console.log(data);
  let rowFilter;
  const handleViewClick = (e) => {
    setViewMobileUnit(true);
    setViewReadOnly(true)
    rowFilter = muDatas.filter((item) => {
      return item.name === e.name;
    });
    console.log(rowFilter[0], "rowFilter array");
    const objData = Object.assign({}, ...rowFilter);
    console.log(objData, "rowFilter object");
    setMappedMobileUnitData(rowFilter[0])
    console.log(mappedMobileUnitData, "mappedMobileUnitData");
  };
  const styles = {
    dataTableStyle: { height: 400, width: "100%", background: "#fff" },
    windowChange: {
      height: "120px",
    },
  };
  const handleAddMobileUnit = () => {
    setAddMobileUnit(true);
  };

  return (
    <Fragment>
      <Button
        variant="outlined"
        sx={{ mb: "15px" }}
        onClick={handleAddMobileUnit}
      >
        Add New
      </Button>
      <Box
        className={`dataTable-block ${addMobileUnit && "windowChange"} ${
          viewMobileUnit && "windowChange"
        }`}
      >
        <Box className="data-table-sub-block" sx={styles.dataTableStyle}>
          <DataGrid
            onRowClick={(e) => handleViewClick(e.row)}
            rows={muDatas}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            // getRowId={(row) => row.mobile_unit_id}
            getRowId={(row) => row.name}
            // checkboxSelection
          />
        </Box>
        <MobileUnitWindowRight setAddMobileUnit={setAddMobileUnit} />
      </Box>
    </Fragment>
  );
};

export default MobileUnitMainBlock;
