import { Box } from "@mui/system";
import React from "react";
import MobileUnitForm from "./MobileUnitForm";
import MobileUnitWindowRightHeader from "./ui/MobileUnitWindowRightHeader";

const MobileUnitWindowRight = (props) => {
  const styles = {
    windowWidth: {
      width: "calc(100% - 300px)",
    },
    windowRightHeader: {
      // padding : "15px"
    },
  };  

  return (
    <>
      <Box className="windowRightBlock" sx={styles.windowWidth}>
        <Box sx={styles.windowRightHeader}>
          <MobileUnitWindowRightHeader setAddMobileUnit={props.setAddMobileUnit} />
          <MobileUnitForm setAddMobileUnit={props.setAddMobileUnit} />
        </Box>
      </Box>
    </>
  );
};

export default MobileUnitWindowRight;
