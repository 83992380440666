import React, { useState, useContext, Fragment, useEffect } from "react";
import { Avatar, Box, Button, Grid, Modal, Snackbar, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Outlet } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { StaffContext } from "./staffContext/StaffContext";
import DeleteIcon from '@mui/icons-material/Delete';
import StaffWindowRight from "./StaffWindowRight";
import recycle from '../../../assets/img/deleteIcon/recycle.svg'

const StaffMainBlock = () => {

  const {
    staffAllData, setStaffAllData,
    addStaff, setAddStaff,
    viewStaff, setViewStaff,
    mappedStaff, setMappedStaff, setViewReadOnly
  } = useContext(StaffContext);

  console.log(staffAllData, "staffAllData ")
  const [open, setOpen] = useState(false);
  const [sanackbarOpen, setSnackbarOpen] = useState(false)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //start delete
  const handleDelete = (e, namename) => {
    console.log(namename, "namename")
    e.stopPropagation();
    let filterd = staffAllData.filter((item) => {
      return item.name != namename.name;
    });
    setOpen(false)
    setSnackbarOpen(true)
    setStaffAllData(filterd)


    console.log(filterd, "filterd")
  }
  //end delete
  const style = {
    modalStyles: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 500,
      bgcolor: 'background.paper',
      border: '2px taransparent #fff',
      boxShadow: 24,
      borderRadius: '14px',
      p: '55px',
    },
    fontStyle: {
      fontFamily: "inherit",
      fontWeight: 700,
      textAlign: 'center',
      mb: "20px"

    },
    iconStyle: {
      margin: ' 0 auto',
      height: '100px',
      width: '100px',

    },
    buttonsStyles: {
      display: 'flex',
      // alignItems:'center',
      justifyContent: "center",
      gap: "20px"
    },
    deleteBtn: {
      borderRadius: '30px',
      letterSpacing: '.8px',
      padding: '5px 15px',
    },
    cancelBtn: {
      borderRadius: '30px',
      letterSpacing: '.8px',
      padding: '5px 15px',
    }

  };
  const columns = [
    {
      field: 'photoURL', headerName: 'Profile Photo', flex: 1,
      renderCell: (params) => <Avatar src={params.row.photoURL} />, sortable: false, filterable: false

    },
    { field: 'name', headerName: 'Staff Name', flex: 1 },
    { field: 'email', headerName: 'E-Mail', flex: 1 },
    { field: 'mobileNumber', headerName: 'Mobile Number', flex: 1, },
    { field: 'designation', headerName: 'Designation', flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <DeleteIcon
              // onClick={(e) => handleDelete(e, params.row)}
              onClick={handleOpen}
            />
            <Modal
              open={open}
              // onClose={handleClose}
            >
              <Box sx={style.modalStyles}>
                <Avatar src={recycle} sx={style.iconStyle} />
                <Typography sx={style.fontStyle} id="modal-modal-title" variant="h6" component="h2">
                  Are you sure you want to delete ?
                </Typography>
                <Box sx={style.buttonsStyles}>
                  <Button variant="text" sx={style.cancelBtn}
                  onClick={handleClose}>Cancel</Button>
                  <Button variant="contained"
                    sx={style.deleteBtn}
                    color="error"
                    onClick={(e) => handleDelete(e, params.row)}
                  >Continue</Button>
                  <Snackbar 
                  message='Successfully Deleted!!'
                  autoHideDuration={4000}
                  sanackbarOpen={sanackbarOpen}
                  />
                </Box>
              </Box>
            </Modal>
          </>
        )
      }
    },

  ]
  const handleViewClick = (e) => {
    // setviewStaff(true);
    setViewReadOnly(true)
    let rowFilter
      = staffAllData.filter((item) => {
        return item.name === e.name;
      });
    console.log(rowFilter[0], "rowFilter array");
    const objData = Object.assign({}, ...rowFilter);
    console.log(objData, "rowFilter object");
    setMappedStaff(rowFilter[0])
    console.log(mappedStaff, "mappedStaff");
  };


  const handleAddStaff = () => {
    setAddStaff(true)
  }
  return (
    <Fragment>
      <Button
        variant="outlined"
        sx={{ mb: "15px" }}
        onClick={handleAddStaff}
      >
        Add Staff
      </Button>
      <Box className={`dataTable-block ${addStaff && "windowChange"} ${viewStaff && "windowChange"
        }`}>
        <Box className="data-table-sub-block" style={{ height: 400, width: '100%', background: "#fff" }}>
          <DataGrid
            onRowClick={(e) => handleViewClick(e.row)}
            rows={staffAllData}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            getRowId={(row) => row.name}
          />
        </Box>
        <StaffWindowRight />
      </Box>
    </Fragment>
  );
};

export default StaffMainBlock;
