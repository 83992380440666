import { Box, Button, Paper, TextField, Grid } from "@mui/material";
import React, { useState } from "react";
import * as  Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from "react-router-dom";
import { InputBase } from '@mui/material';

const defaultInputValues = {
  email: '',
  password: ''
}



const LoginForm = () => {
  const [details, setDetails] = useState(defaultInputValues);
  let navigate = useNavigate();
  const emailvalid = "seahorse@gmail.com";
  const passwordvalid = "seahorse";

  const modalStyles = {
    inputFields: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '40px',
      alignItems: 'center',
      marginBottom: '15px',
      '.MuiFormControl-root': {
        marginBottom: '20px',
        width: '75%',
        },
        '& label': {
          color: "warning.main"
        },
        // "&.Mui-focused": {
        //   color:"#fff"
        // },
        // "& .MuiInput-Outlined:before" :{
        //   color:"#fff",
        // },
        // '&.MuiInputBase-root' :{
        //   color:"#fff",
        // },
        // '	&.MuiOutlinedInput-root':{
        //   color:"#fff"
        // },
        // '&.MuiInputBase-colorPrimary': {
        //   borderColor:"warning.main"
        // } 
        
    },
    paperStyles: {

    },
    




  }
  
  const validationSchema = Yup.object().shape({

    email: Yup.string()

      .required('Email is required')
      .matches(emailvalid, 'Email is not valid'),


    password: Yup.string()
      .required('password is required')
      .matches(passwordvalid, 'Password is not valid'),


  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema)
  });



  const handleChange = (value) => {
    setDetails(value)
  };







  const onSubmit = (data) => {
    console.log(data);
    navigate("./console")

  }


  return (



    <Box sx={{ width: "100%", alignItems: "left" }} >
      <form onSubmit={handleSubmit(onSubmit)} >
        <Box sx={modalStyles.inputFields}>

          <TextField
          color="warning"
             placeholder="seahorse@gmail.com"
            // name="email"
            label="Email"
            autoComplete="off"
            // required
            variant= "outlined"
            {...register('email')}
            error={errors.email ? true : false}
            helperText={errors.email?.message}
            value={details.email}
            onChange={(event) => setDetails({ ...details, email: event.target.value })}
          />
          <TextField
           color="warning"
            placeholder="Password"
            autoComplete="off"
            // name="password"
            label="Password"
            type="password"
            // required
            {...register('password')}
            error={errors.password ? true : false}
            helperText={errors.password?.message}
            value={details.password}
            onChange={(event) => setDetails({ ...details, password: event.target.value })}
          />

          <Button variant="contained" color="warning" type="submit">
            Login
          </Button>
        </Box>
      </form>
    </Box>






  );
};

export default LoginForm;