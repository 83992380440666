import { Avatar, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { login } from "../api/auth/sap/login";
import { loginAPI } from "../../api/auth/login";

// import "../assets/css/main-admin.css";
// import "../../assets/css/main-admin.css";
// import isSAPAuthenticated from "../../utils/isSUPAuthenticated";
import isSUPAuthenticated from "../../utils/isSUPAuthenticated";
import loginLogo from "../../assets/img/logo/logo.png"
import LoginForm from "../login/LoginForm";
import bgImage from "../../assets/img/bg/1.png";
import { Box } from "@mui/system";

const Login = () => {
  const paperStyles = {
    padding: 0,
    width: 450,
    margin: "0 auto",
    backgroundColor: '#ffffff1a',
    borderRadius: '10px',
    backdropFilter: 'blur(6px)',


  }
  const gridStyles = {
    height: "100vh",
    display: 'flex',
    alignItems: 'center',
  }
  const bgStyles = {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: ' no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
  }

  const [error, setError] = useState("");

  const bg = require("../../assets/img/main-admin-img/logo/sample.png");
  let navigate = useNavigate();

  const handleClick = () => {
    if (isSUPAuthenticated()) {
      navigate('console');
    }
  }

  const loginAction = async (details) => {
    loginAPI(details).then((res) => { handleClick() }).catch((e) => { setError(e.message) });
  };

  return (
   
                  <Box sx={bgStyles}>
                   
                    <Grid sx={gridStyles}>
                      <Paper elevation={10} sx={paperStyles}>

                        <Avatar src={loginLogo} alt="logo"
                          sx={{ width: '140px', height: "140px", margin: "0 auto" }} />

                        <h3 className="mb-4 text-center">Seahorse</h3>

                        <LoginForm Login={loginAction} error={error} />
                      </Paper>
                    </Grid>
                  </Box>
    
  );
};

export default Login;
