import React from "react";
// import  '../../../assets/css/bootstrap.min.css';
import "../../../assets/fontawesome/css/fontawesome-all.css";
// import "../../../assets/css/admin-custom.css";
import { Scrollbars } from "react-custom-scrollbars-2";
import mainLogo from "../../../assets/img/logo/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { SidebarList } from "./SidebarList";
import { Avatar } from "@mui/material";

const Sidebar = () => {
  const navigate = useNavigate();
  const drawerWidth = 240;
  const logo = require("../../../assets/img/logo/logo.png");

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box
          sx={{
            height: "64px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Avatar alt="logo" src={logo} sx={{height: "100%" , width : "100%" , height: "64px" , width: "64px"}} />
        </Box>
        {/* <Divider /> */}
        <List sx={{mt: "20px"}}>
          {SidebarList.map((item, index) => (
            <ListItem key={item.id} button onClick={() => navigate(item.route)}>
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {/* <Divider /> */}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
