import {
  Button,
  Checkbox,
  FormLabel,
  Grid,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import * as Yup from "yup";
import { useForm, useController } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { MobileUnitContext } from "./context/MobileUnitContext";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";

const MobileUnitForm = (props) => {
  const {
    muDatas,
    setMUDatas,
    mappedMobileUnitData,
    setMappedMobileUnitData,
    setAddMobileUnit,
    addMobileUnit,
    viewMobileUnit,
    setViewMobileUnit,
    viewReadOnly,
    setViewReadOnly,
    editMobileUnit,
    setEditMobileUnit,
    handleMuEdit,
  } = useContext(MobileUnitContext);
  // const { data, ...rest } = muDatas;

  const styles = {
    formStyle: {
      padding: "15px",
    },
    formRowStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      mb: "15px",
    },
    iconLabelStyles: {
      display: "flex",
      gap: "20px",
    },
    textField: {
      width: "75%",
    },
    btnStyle: {},
  };
  const validationSchema = Yup.object().shape({
    // name: Yup.string()
    //   .required("MU Name is required")
    //   .min(6, "MU Name must be atleast 6 characters"),
    // emirate_name: Yup.string()
    //   .required("MU Name is required")
    //   .min(6, "MU Name must be atleast 6 characters"),
    // salary: Yup.string()
    //   .required("salary is required")
    //   .min(6, "salary must be atleast 6 characters"),
    // age: Yup.string()
  });

  // const {
  //   register,
  //   handleSubmit,
  //   reset,
  //   control,
  //   formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(validationSchema),
  // });
  // const { field } = useController({ name: "status", control });
  // console.log(field , "field")
  const [editMobileUnitId, setEditMobileUnitId] = useState();

  let initialState = {
    mobile_unit_id: "",
    name: "",
    emirates_id: "",
    vehicle_number: "",
    contact_number: "",
    status: "",
    created_at: "",
    updated_at: "",
    emirate_name: "",
  };
  const [allData, setAllData] = useState(initialState);
  const [salary, setSalary] = useState("");
  const [gender, setGender] = useState();
  const handleSubmit = (e) => {
    e.preventDefault();

    // setAddMobileUnit(false);
    // setViewMobileUnit(false);

    if (editMobileUnit) {
      handleMuEdit(
        {
          ...allData,
          salary: salary,
        },
        editMobileUnitId
      );
    } else {
      setMUDatas((prev) => [
        ...prev,
        { ...allData, salary: salary, selected: selected  },
      ]);
    }
    console.log(muDatas, "MuDats");
    setAllData(initialState);
    setSalary("");
    setSelected([]);
  };
  useEffect(() => {
    console.log("viewMobileUnit");
    // setAllData(mappedMobileUnitData)
    // setSalary(mappedMobileUnitData.salary)
    let { salary, selected, ...rest } = mappedMobileUnitData;
    setEditMobileUnitId(rest.name);
    setAllData(rest);
    setSalary(salary);
    // setSelected(selected)
  }, [viewMobileUnit]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  const options = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];

  const [selected, setSelected] = useState([]);
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      return;
    }
    setSelected(value);
  };
  console.log(selected, "selected");
  console.log({ ...selected }, "obj");
  return (
    <Box sx={styles.formStyle}>
      <form onSubmit={handleSubmit}>
        {/* {mappedMobileUnitData.selected &&
          mappedMobileUnitData.selected.map((e) => {
            return e;
          })} */}
        <Box sx={styles.formRowStyle}>
          <Box sx={styles.iconLabelStyles}>
            <DriveFileRenameOutlineIcon />
            <label> Name</label>
          </Box>
          <TextField
            inputProps={{
              readOnly: Boolean(viewReadOnly),
            }}
            value={allData.name}
            onChange={(e) => setAllData({ ...allData, name: e.target.value })}
            sx={styles.textField}
            placeholder="name"
            label="name"
            // {...register("name")}
            // error={errors.name ? true : false}
            // helperText={errors.name?.message}
          />
        </Box>
        <Box sx={styles.formRowStyle}>
          <Box sx={styles.iconLabelStyles}>
            <DriveFileRenameOutlineIcon />
            <label>Emirate Name</label>
          </Box>
          <TextField
            inputProps={{
              readOnly: Boolean(viewReadOnly),
            }}
            value={allData.emirate_name}
            onChange={(e) =>
              setAllData({ ...allData, emirate_name: e.target.value })
            }
            sx={styles.textField}
            placeholder="emirate_name"
            label="emirate_names"
            // {...register("emirate_name")}
            // error={errors.emirate_name ? true : false}
            // helperText={errors.emirate_name?.message}
          />
        </Box>
        <Box sx={styles.formRowStyle}>
          <Box sx={styles.iconLabelStyles}>
            <DriveFileRenameOutlineIcon />
            <label> contact_number</label>
          </Box>
          <TextField
            inputProps={{
              readOnly: Boolean(viewReadOnly),
              // disabled: Boolean(viewMobileUnit),
            }}
            value={allData.contact_number}
            onChange={(e) =>
              setAllData({ ...allData, contact_number: e.target.value })
            }
            sx={styles.textField}
            placeholder="name"
            label="name"
            // {...register("contact_number")}
            // error={errors.contact_number ? true : false}
            // helperText={errors.contact_number?.message}
          />
        </Box>
        <Box sx={styles.formRowStyle}>
          <Box sx={styles.iconLabelStyles}>
            <DriveFileRenameOutlineIcon />
            <label> salary</label>
          </Box>
          <TextField
            inputProps={{
              readOnly: Boolean(viewReadOnly),
            }}
            value={salary}
            onChange={(e) => setSalary(e.target.value)}
            sx={styles.textField}
            placeholder="salary"
            label="salary"
            // {...register("salary")}
            // error={errors.salary ? true : false}
            // helperText={errors.salary?.message}
          />
        </Box>
        <Box sx={styles.formRowStyle}>
          <Box sx={styles.iconLabelStyles}>
            <DriveFileRenameOutlineIcon />
            <label> Emirates</label>
          </Box>
          <FormControl sx={{ width: "75%" }}>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              inputProps={{
                readOnly: Boolean(viewReadOnly),
                disabled: Boolean(viewReadOnly),
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={allData.status}
              label="Status"
              defaultValue={2}
              onChange={(e) =>
                setAllData({ ...allData, status: e.target.value })
              }
            >
              <MenuItem value={1}>Available</MenuItem>
              <MenuItem value={0}>Off Day</MenuItem>
              <MenuItem value={2}>Engaged</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={styles.formRowStyle}>
        <Box sx={styles.iconLabelStyles}>
            <DriveFileRenameOutlineIcon />
            <label>Choose Service</label>
          </Box>
          <FormControl sx={{ width: "75%" }}>
            <InputLabel id="mutiple-select-label">Multiple Select</InputLabel>
            <Select
              labelId="mutiple-select-label"
              multiple
              value={selected}
              onChange={handleChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              <MenuItem value="all">
                <ListItemIcon>
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={
                      selected.length > 0 && selected.length < options.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Select All" />
              </MenuItem>
              {options.map((option) => (
                <MenuItem key={option} value={option}>
                  <ListItemIcon>
                    <Checkbox checked={selected.indexOf(option) > -1} />
                  </ListItemIcon>
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {viewReadOnly ? (
          ""
        ) : (
          <Box sx={{ textAlign: "right", mt: "5px" }}>
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default MobileUnitForm;
