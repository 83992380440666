import { Box } from "@mui/system";
import React, { useContext } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useForm } from "react-hook-form";
import { MobileUnitContext } from "../context/MobileUnitContext";
import EditIcon from "@mui/icons-material/Edit";
import { Grid } from "@mui/material";
const MobileUnitWindowRightHeader = (props) => {
  const {
    muDatas,
    setMUDatas,
    mappedMobileUnitData,
    setMappedMobileUnitData,
    setAddMobileUnit,
    addMobileUnit,
    viewMobileUnit,
    setViewMobileUnit,
    editMobileUnit,
    setEditMobileUnit,setViewReadOnly
  } = useContext(MobileUnitContext);
  const styles = {
    addHeaderStyle: {
      display: "flex",
      alignItems: "Center",
      padding: "15px",
      borderBottom: "1px solid #ff000014",
    },
  };
  const handleMuExit = () => {
    setAddMobileUnit(false);
    setViewMobileUnit(false);
    console.log("exit view mu");
  };
  const editMU = () => {
    setEditMobileUnit(true);
    setViewReadOnly(false)
    console.log("hai");
  };
  return (
    <Box sx={styles.addHeaderStyle}>
      <KeyboardBackspaceIcon sx={{ mr: "10px" }} onClick={handleMuExit} />
      <h6>
        {editMobileUnit
          ? `Edit ${mappedMobileUnitData.name}`
          : viewMobileUnit
          ? `View ${mappedMobileUnitData.name}`
          : "Add MU"}
      </h6>
      <Grid>
        {viewMobileUnit && (
          <button sx={{ ml: "100px" }} onClick={editMU}>
            <EditIcon />
          </button>
        )}
      </Grid>
    </Box>
  );
};

export default MobileUnitWindowRightHeader;
