import React from "react";
import Sidebar from "./Sidebar";
// import "../../../assets/css/admin-custom.css";
import "../../../assets/css/bootstrap.min.css";
import { Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "./Header";
const Layout = (props) => {
  return (
    <section id="main" className="main">
      <div className="container-fluid p-0">
        <div className="row m-0">
          <Grid container>
            <Header />
            <Sidebar />
            <Outlet />
          </Grid>
        </div>
      </div>
    </section>
  );
};
export default Layout;
