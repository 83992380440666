import axios from 'axios';
// import sapAuthToken from '../utils/sapAuthToken';
// import supAuthToken from '../utils/supAuthToken';

const consoleAPI = () => {
    const consoleAPI = axios.create({
        baseURL: 'r',
        timeout: 2000,
        headers: { 'Authorization': 'Bearer ' }
    });

    consoleAPI.interceptors.request.use((req) => {
        console.log('req', req);
        return req;
    }, (err) => {
        console.log('req-err', err);
        return Promise.reject(err);
    });
    consoleAPI.interceptors.response.use((res) => {
        // console.log('res', res);
        // if (res.data.data.reload) {
        if (res.data.message === 'Please authenticate.') {
            // localStorage.removeItem('isSAPAuthenticated');
            // localStorage.removeItem('sapAuthToken');
            // window.location.reload();
        }
        return res.data;
    }, (err) => {
        console.log('res-err', err);
        return Promise.reject(err);
    });

    return consoleAPI;
}

const commonAPI = () => {
    let token = '';
    // if (supAuthToken()) {
    //     token = supAuthToken();
    // }
    // if (sapAuthToken()) {
    //     token = sapAuthToken();
    // }
    const commonAPI = axios.create({
        baseURL: 'http://localhost:8081/common',
        timeout: 1000,
        headers: { 'Authorization': `Bearer ${token}` }
    });

    commonAPI.interceptors.request.use((req) => {
        return req;
    }, (err) => {
        console.log('req-err', err);
        return Promise.reject(err);
    });
    commonAPI.interceptors.response.use((res) => {
        if (res.data.message === 'Please authenticate.') {
            // localStorage.removeItem('isSUPAuthenticated');
            // localStorage.removeItem('supAuthToken');
            // window.location.reload();
        }
        return res.data;
    }, (err) => {
        console.log('res-err', err);
        return Promise.reject(err);
    });

    return commonAPI;
}

export { consoleAPI, commonAPI };