
import { useState } from "react";
import { createContext } from "react";
import {image} from '../../../../assets/img/profileimg/profile1.jpg'
export const StaffContext = createContext();

export const StaffProvider = (props) => {
    const [staffAllData, setStaffAllData] = useState([
        {
            staff_id: 1,
            name: "Bismina",
            email: "bismina229@gamil.com",
            mobileNumber: "7012060271",
            designation: "manager",
            address: "dummydummy",
            gender: "female",
            dob: "22-01-2000",
            blood_group: "A+",
            date_of_joining: "12-04-2022",
            type: "adarcard",
            photoURL:"https://picsum.photos/200/300"



        },
        {
            staff_id: 2,
            name: "Rahul",
            email: "Rahul567@gamil.com",
            mobileNumber: "3456789023",
            designation: "staff",
            photoURL:"https://picsum.photos/200"

        },

    ]);
    const [addStaff, setAddStaff] = useState(false);
    const [viewStaff, setViewStaff] = useState(false);
    const [editStaff, setEditStaff] = useState(false);
    const [mappedStaff, setMappedStaff] = useState({});
    const [viewReadOnly, setViewReadOnly] = useState(false);
    const handleMuEdit = () => {
        console.log("first")
      }
    let initialValues = {
        staffAllData,
        setStaffAllData,
        addStaff,
        setAddStaff,
        viewStaff,
        setViewStaff,
        editStaff,
        setEditStaff,
        mappedStaff,
        setMappedStaff,
        viewReadOnly,
        setViewReadOnly,
        handleMuEdit




    };
    return (
        <StaffContext.Provider value={initialValues}>
            {props.children}
        </StaffContext.Provider>
    )
}