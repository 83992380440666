import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import icon from "../../../assets/img/icon/icon.jpg";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

function Header() {
  const headerStyles = {
    Wrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    icon: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "15px",
      mr:'10px',
    },
  };

  const drawerWidth = 240;
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          background:"#D9D9D994"
        }}
      >
        <Toolbar sx={headerStyles.Wrapper}>
          <Box>
            <Typography variant="h6" noWrap component="div">
              Seahorse
            </Typography>
          </Box>
          <Box sx={headerStyles.icon}>
           
            <NotificationsIcon />
            <Avatar alt="icon" src={icon} />
            {/* <AccountCircleIcon /> */}
          </Box>
        </Toolbar>
      </AppBar>
      {/* <Outlet /> */}
    </>
  );
}

export default Header;
