import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./pages/login/Login";
import Console from "./pages/console";
import Staff from "./pages/console/staff/Staff";
import Service from "./pages/console/service/Service";
import Layout from "./pages/console/layout/Layout";
import MobileUnit from "./pages/console/mobileunit/MobileUnit";

function App() {
  return (
    <div className="App theme-light">
      {/* <Login /> */}
      {/* <Dashboard/> */}
      <Router>
        <Routes>
          <Route>
            <Route path="/" element={<Login />} />
          </Route>
          <Route path="/console" element={<Layout />}>
            <Route path="/console/staff" element={<Staff />} />
            <Route path="/console/services" element={<Service />} />
            <Route path="/console/mobileunit" element={<MobileUnit />} />

          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
