import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import CarRepairIcon from '@mui/icons-material/CarRepair';

export const SidebarList = [
  {
    id: 0,
    icon: <PersonAddAltIcon />,
    label: "Staff",
    route: "/console/staff",
  },
  {
    id: 1,
    icon: <MiscellaneousServicesIcon />,
    label: "Services",
    route: "/console/services",
  },
  {
    id: 2,
    icon: <CarRepairIcon />,
    label: "Mobile Unit",
    route: "/console/mobileunit",
  },
];
