import { Box, TextField } from '@mui/material';
import React, { useContext, useState } from 'react'
import { StaffContext } from './staffContext/StaffContext';

function StaffForm() {
    const { setAddStaff, addStaff, viewStaff, setViewStaff } = useContext(StaffContext);
    const styles = {
        formStyle: {
            padding: "15px",
        },
        formRowStyle: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "15px",
        },
        iconLabelStyles: {
            display: "flex",
            gap: "20px",
        },
        textField: {
            width: "75%",
        },
        btnStyle: {},
    };
    let initialState = {
        mobile_unit_id: "",
        name: "",
        emirates_id: "",
        vehicle_number: "",
        contact_number: "",
        status: "",
        created_at: "",
        updated_at: "",
        emirate_name: "",
      };
      const [allData, setAllData] = useState([initialState]);
    return (
       <>
       hii
       </>
    )
}

export default StaffForm