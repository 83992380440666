import { Box } from '@mui/material';
import React from 'react'
import { useContext } from 'react'
import { StaffContext } from './staffContext/StaffContext'
import StaffForm from './StaffForm';
import StaffWindowRightHeader from './StaffWindowRightHeader';

function StaffWindowRight() {
    const { addStaff,setAddStaff } = useContext(StaffContext);
    const styles = {
        windowWidth: {
          width: "calc(100% - 300px)",
        },
       
      };  
  return (

     <Box className="windowRightBlock" sx={styles.windowWidth}>
         <StaffWindowRightHeader setAddStaff={setAddStaff}/>
         <StaffForm setAddStaff={setAddStaff}/>
        </Box>
  )
}

export default StaffWindowRight