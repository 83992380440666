import React, { useState, createContext, useEffect } from "react";

export const MobileUnitContext = createContext();

export const MobileUnitProvider = (props) => {
  const [muDatas, setMUDatas] = useState([
    {
      mobile_unit_id: 1,
      name: "Salman MU 3",
      emirates_id: 1,
      vehicle_number: "A12345",
      contact_number: "01197155",
      status: 1,
      created_at: "2022-11-17T06:34:24.000Z",
      updated_at: "2022-11-17T06:34:24.000Z",
      emirate_name: "Abu Dhabi",
    },
    {
      mobile_unit_id: 2,
      name: "MU 2",
      emirates_id: 1,
      vehicle_number: "A12345",
      contact_number: "01197155",
      status: 2,
      created_at: "2022-11-17T06:34:22.000Z",
      updated_at: "2022-11-17T06:34:22.000Z",
      emirate_name: "Dubai",
    },
    {
      mobile_unit_id: 2,
      name: "Bismina MU 2",
      emirates_id: 1,
      vehicle_number: "A12345",
      contact_number: "01197155",
      status: 0,
      created_at: "2022-11-17T06:34:22.000Z",
      updated_at: "2022-11-17T06:34:22.000Z",
      emirate_name: "Dubai",
    },
  ]);
  const [addMobileUnit, setAddMobileUnit] = useState(false);
  const [viewMobileUnit, setViewMobileUnit] = useState(false);
  const [editMobileUnit, setEditMobileUnit] = useState(false);
  const [mappedMobileUnitData, setMappedMobileUnitData] = useState({});

  const [viewReadOnly, setViewReadOnly] = useState(false);
const handleMuEdit = (newData , id) => {
  let editData = muDatas.map((item) => {
    if(item.name === id){
      return newData
    }
    return item;
  });
  setMUDatas(editData)
  console.log("first")
}
  let initialValues = {
    muDatas,
    setMUDatas,
    addMobileUnit,
    setAddMobileUnit,
    viewMobileUnit,
    setViewMobileUnit,
    mappedMobileUnitData,
    setMappedMobileUnitData,
    editMobileUnit,
    setEditMobileUnit,viewReadOnly , setViewReadOnly,handleMuEdit
  };
  return (
    <MobileUnitContext.Provider value={initialValues}>
      {props.children}
    </MobileUnitContext.Provider>
  );
};
