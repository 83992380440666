import { Box, Grid } from '@mui/material';
import React, { useContext } from 'react'
import { StaffContext } from './staffContext/StaffContext'
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EditIcon from "@mui/icons-material/Edit";
function StaffWindowRightHeader() {
  const { setAddStaff, addStaff, viewStaff, setViewStaff,
    editStaff, mappedStaff, setEditStaff, setViewReadOnly


  } = useContext(StaffContext);
  const styles = {
    addHeaderStyle: {
      display: "flex",
      alignItems: "Center",
      padding: "15px",
      borderBottom: "1px solid #ff000014",
    },
  };
  const handleMuExit = () => {
    setAddStaff(false);
    setViewStaff(false);
    console.log("exit view mu");
  };
  const editMU = () => {
    setEditStaff(true);
    setViewReadOnly(false)
    console.log("hai");
  };
  return (
    <Box sx={styles.addHeaderStyle}>
      <KeyboardBackspaceIcon sx={{ mr: "10px" }} onClick={handleMuExit} />
      <h6>
        {editStaff
          ? `Edit ${mappedStaff.name}`
          : viewStaff
            ? `View ${mappedStaff.name}`
            : "Add Staff"}
      </h6>
      <Grid>
        {viewStaff
          && (
            <button sx={{ ml: "100px" }} onClick={editMU}>
              <EditIcon />
            </button>
          )
        }
      </Grid>
    </Box>
  )
}

export default StaffWindowRightHeader