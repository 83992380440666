import React, { useState, useContext, Fragment, useEffect } from "react";
import { Button, Grid } from "@mui/material";

import "../../console/mobileunit/ui/mu.css";
import MobileUnitMainBlock from "./MobileUnitMainBlock";
import { MobileUnitProvider } from "./context/MobileUnitContext";
const MobileUnit = (props) => {
  const drawerWidth = 240;
  const topWidth = 70;

  return (
    <>
      <Grid
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          mt: `${topWidth}px`,
          textAlign: "left",
          padding: "25px",
          background: "#D9D9D994",
          height: "calc(100vh - 64px)",
          overflow: "auto",
        }}
      >
        <MobileUnitProvider>
          <MobileUnitMainBlock />
        </MobileUnitProvider>
      </Grid>
    </>
  );
};

export default MobileUnit;
