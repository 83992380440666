import React, { useState, useContext, Fragment, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Outlet } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import StaffMainBlock from "./StaffMainBlock";
import { StaffProvider } from "./staffContext/StaffContext";
import "../../console/mobileunit/ui/mu.css";

const Staff = (props) => {
  const drawerWidth = 240;
  const topWidth = 70;
  let b = "success";

  return (
    <>
      <Grid
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          mt: `${topWidth}px`,
          textAlign: "left",
          padding: "25px",
          background: "#D9D9D994",
          height: "calc(100vh - 64px)",
          overflow: "auto",
        }}
      >
        <StaffProvider>
          <StaffMainBlock />
        </StaffProvider>

        
      </Grid>
    </>
  );
};

export default Staff;
